export const appCdnURL = "https://cdn.arora.pro/k/upload/generated/bb617900-ba65-4df5-b7c5-6ab02cd62f46/d9ee66e4-fbfd-4a71-a13b-621ac9006a14/dist"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appHead = {"htmlAttrs":{"lang":"ru"},"link":[{"href":"https://okinavasushi.ru/upload/bb617900-ba65-4df5-b7c5-6ab02cd62f46/file_manager/okinavasushi.ru/theme/favicon.png","rel":"icon","type":"image/png"}],"meta":[{"content":"utf-8","name":"charset"},{"content":"P903.314","name":"website-version"},{"content":"no-cache","name":"cache-control"},{"content":"width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no","name":"viewport"},{"content":"#EC1F27","name":"theme-color"},{"content":"food","property":"og:type"},{"content":"https://okinavasushi.ru/upload/bb617900-ba65-4df5-b7c5-6ab02cd62f46/file_manager/okinavasushi.ru/theme/popup-mob.jpg","property":"og:image"},{"content":"https://okinavasushi.ru/","property":"og:site_name"}]}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"prefetch":false,"componentName":"NuxtLink","prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false